import dayjs from 'dayjs';

import { DATE_FORMAT } from 'shared/const';

export const getPayloadWithFormattedDates = (
  payload: Record<string, unknown>,
  additionalFieldsList?: string[],
) =>
  Object.keys(payload).reduce((acc: Record<string, unknown>, key) => {
    if (key.includes('timestamp')) {
      const [first, second] = payload[key] as string[];
      const firstDate = dayjs(first).isValid()
        ? dayjs(first)
            .set('hour', 0)
            .set('minutes', 0)
            .set('second', 0)
            .toISOString()
        : '';
      const secondDate = dayjs(second).isValid()
        ? dayjs(second)
            .set('hour', 23)
            .set('minutes', 59)
            .set('second', 59)
            .toISOString()
        : '';
      return {
        ...acc,
        [key]: `${firstDate},${secondDate}`,
      };
    }
    if (key.includes('date') || additionalFieldsList?.includes(key)) {
      const dates = payload[key] as string[] | string;
      if (Array.isArray(dates)) {
        return {
          ...acc,
          [key]: dates.map((date) =>
            dayjs(date).isValid() ? dayjs(date).format(DATE_FORMAT) : '',
          ),
        };
      }
      return {
        ...acc,
        [key]: dayjs(dates).isValid() ? dayjs(dates).format(DATE_FORMAT) : '',
      };
    }
    return { ...acc, [key]: payload[key] };
  }, {});
