import { SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { ROUTES } from 'routes';
import { ITask } from 'services/CorporateActions';
import { FetchedColumn } from 'services/types';
import { ColumnsWithFilters } from 'types';
import { getTableColumns } from 'utils/getTableColumns';

import { ModalParams } from '../types';

export const getColumns = ({
  onFilter,
  onRemove,
  onChangeModalParams,
  columnsList,
}: ColumnsWithFilters & {
  onChangeModalParams: (params: ModalParams) => void;
  columnsList: FetchedColumn[];
}) => {
  const columns: IColumn<ITask>[] = [
    ...getTableColumns({
      fetchedColumns: columnsList,
      excludeColumns: ['file_link', 'initial_count', 'message', 'params'],
      onFilter,
      onRemove,
    }),
    {
      Header: 'link to source',
      accessor: 'file_link',
      minWidth: 40,
      Cell: (cell: ICellValue<ITask>) =>
        cell?.row.original.file_link ? (
          <a href={cell?.row.original.file_link}>Link</a>
        ) : (
          'no link'
        ),
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'link to events',
      accessor: 'initial_count',
      minWidth: 40,
      Cell: (cell: ICellValue<ITask>) => (
        <Link
          to={`${ROUTES.CORPORATE_ACTIONS}?task_id=${cell?.row.original.id}`}
        >
          Link
        </Link>
      ),
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Params',
      accessor: 'params',
      minWidth: 260,
      Cell: (cell: ICellValue<ITask>) => {
        const handleClickOnLink = (event: SyntheticEvent) => {
          event.preventDefault();
          onChangeModalParams({
            title: 'Params',
            isShow: true,
            description: JSON.stringify(cell?.row.original.params) ?? '',
          });
        };

        const keys = Object.keys(cell?.row.original.params ?? {});

        if (keys.length === 0) {
          return <div>no params</div>;
        }
        if (keys.length === 1) {
          return <div>{JSON.stringify(cell?.row.original.params)}</div>;
        }
        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a href="" onClick={handleClickOnLink}>
            Show
          </a>
        );
      },
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'logs',
      accessor: 'message',
      minWidth: 80,
      Cell: (cell: ICellValue<ITask>) => {
        const handleClickOnLink = (event: SyntheticEvent) => {
          event.preventDefault();
          onChangeModalParams({
            title: 'Logs',
            isShow: true,
            description: cell?.row.original.message ?? '',
          });
        };
        return (
          <div>
            {cell?.row.original.message ? (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a href="" onClick={handleClickOnLink}>
                Show
              </a>
            ) : (
              'no logs'
            )}
          </div>
        );
      },
      disableFilters: true,
      disableSortBy: true,
    },
  ];
  return columnsList.length ? columns : [];
};
