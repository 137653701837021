import jsCookie from 'js-cookie';
import qs from 'qs';
import { ISortBy, TCommonParams } from 'react-ui-kit-exante';

import {
  SESSION_ID_COOKIE_KEY,
  SESSION_GOOGLE_COOKIE_KEY,
  CAS_APIV1,
  PROD_API,
  TEST_API,
  STAGE_API,
} from './constants';

export function getSessionIdFromCookie(sessionKey?: string) {
  return jsCookie.get(SESSION_ID_COOKIE_KEY || sessionKey);
}

export function getAccessTokenFromCookie(accessTokenKey?: string) {
  const token = jsCookie.get(SESSION_GOOGLE_COOKIE_KEY || accessTokenKey);
  return token && JSON.parse(token);
}

export const paramsSerializer = (params: Record<string, unknown>) =>
  qs.stringify(params, {
    arrayFormat: 'comma',
    encode: true,
    skipNulls: true,
  });

export function getCorporateActionsBaseUrl(apiVersion = CAS_APIV1) {
  const isStage = window.location.host.includes('stage');
  const isTest = window.location.host.includes('test');
  const isLocalHost = window.location.host.includes('localhost');
  const isProd = !(isStage || isLocalHost || isTest);

  const bridgedApiUrl =
    window.CORPORATE_ACTIONS_UI?.api_urls?.CORPORATE_ACTIONS_UI;

  let caApiUrl = TEST_API.CA;
  if (isProd) {
    caApiUrl = PROD_API.CA;
  } else if (isStage) {
    caApiUrl = STAGE_API.CA;
  }
  if (!bridgedApiUrl) {
    return `https://${caApiUrl}${apiVersion}`;
  }

  return `https://${bridgedApiUrl}${apiVersion}`;
}

export const prepareParams = ({
  sorting,
  skip,
  page,
  ...rest
}: TCommonParams) => ({
  ...(sorting as ISortBy),
  offset: page,
  ...rest,
});

export const getErrorMessage = (err: any) => {
  if (err.response) {
    const { data } = err.response;

    if (data.message !== '') {
      throw new Error(data.message);
    }

    throw new Error(`${err.response.status} ${err.response.statusText}`);
  }

  throw new Error(err);
};
