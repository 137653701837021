export enum Headers {
  X_AUTH_SESSIONID = 'x-auth-sessionid',
  CONTENT_TYPE = 'Content-Type',
  AUTHORIZATION = 'Authorization',
}

export enum Methods {
  DELETE = 'DELETE',
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  HEAD = 'HEAD',
}

export const PROD_API = {
  CA: 'corporate-actions-api.exante.eu',
};

export const STAGE_API = {
  CA: 'corporate-actions-api-stage.exante.eu',
};

export const TEST_API = {
  CA: 'corporate-actions-api-test.exante.eu',
};

export const CAS_APIV1 = '/api/v1.0';
export const CAS_APIV1_1 = '/api/v1.1';

export const SESSION_ID_COOKIE_KEY = 'run-ui_corporate-actions_session_id';
export const SESSION_GOOGLE_COOKIE_KEY = 'run-ui_access_token';
