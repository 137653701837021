import { FC } from 'react';
import { Panel, IconButton, styled } from 'react-ui-kit-exante';

import { FormHeaderProps } from './types';

const Header = styled('div')(({ theme }) => ({
  backgroundColor: theme?.color.bg.primary,
  display: 'flex',
  gap: '24px',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '32px',
  boxSizing: 'border-box',
}));

export const FormHeader: FC<FormHeaderProps> = ({
  isSubmitting,
  isDirty,
  text,
  handleClose,
}) => (
  <Panel
    title={text}
    action={
      <Header>
        <IconButton
          type="submit"
          iconName="SaveIcon"
          disabled={isSubmitting || !isDirty}
          data-test-id="user-management-module__button--save"
        />
        <IconButton
          data-test-id="user-management-module__button--close"
          iconName="CloseIcon"
          onClick={handleClose}
        />
      </Header>
    }
  />
);
