import React, { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Tabs, Tab, Panel } from 'react-ui-kit-exante';

import { getEventsTypes } from 'services/CorporateActions';
import { EventTypes } from 'services/types';

import { CorporateActionsTable } from './CorporateActionsTable';

const tableTabStorageId = 'CAEventType';

export const CorporateActions: FC = () => {
  const history = useHistory();

  const [eventTypes, setEventTypes] = useState<EventTypes | null>(null);
  const [selectedTab, setSelectedTab] = useState(
    localStorage.getItem(tableTabStorageId),
  );
  useEffect(() => {
    if (!selectedTab && eventTypes) {
      setSelectedTab(Object.keys(eventTypes ?? [])[0]);
    }
  }, [eventTypes]);

  const getEventTypes = async () => {
    const response = await getEventsTypes();
    if (response?.possible_types) {
      setEventTypes(response?.possible_types);
    }
  };

  useEffect(() => {
    getEventTypes();
  }, []);

  const handleChangeTab = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const tabs = useMemo(
    () =>
      Object.keys(eventTypes ?? {}).map((key) => ({
        name: key,
        items: (eventTypes ?? {})[key],
      })),
    [eventTypes],
  );
  useEffect(() => {
    localStorage.setItem(tableTabStorageId, `${selectedTab}`);
    history.push(window.location.pathname);
  }, [selectedTab]);

  const [isShow, setIsShow] = useState(true);
  useEffect(() => {
    setIsShow(false);
    setTimeout(() => {
      setIsShow(true);
    }, 1000);
  }, [selectedTab]);
  return (
    <Panel>
      <Tabs value={selectedTab} onChange={handleChangeTab} className="ml-4">
        {tabs.map((tab) => (
          <Tab
            key={tab.name}
            label={tab.name}
            value={tab.name}
            onClick={() => {
              setSelectedTab(tab.name);
            }}
          />
        ))}
      </Tabs>
      {isShow && (
        <CorporateActionsTable
          tableId={`${selectedTab}-CorporateActionsTable`}
          selectedTab={selectedTab ?? ''}
          eventTypes={eventTypes ?? {}}
        />
      )}
    </Panel>
  );
};
