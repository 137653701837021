import { ROUTES } from '../../routes';

export const NAV_LINKS = [
  {
    to: ROUTES.CORPORATE_ACTIONS,
    label: 'Corporate Actions ',
  },
  {
    to: ROUTES.NEW_CORPORATE_ACTION,
    label: 'Add new CA',
  },
  {
    to: ROUTES.CORPORATE_ACTION_SETTINGS,
    label: 'Settings',
  },
  {
    to: ROUTES.CORPORATE_ACTION_SCRIPTS,
    label: 'Manage Scripts',
  },
  {
    to: ROUTES.POSTINGS,
    label: 'Postings',
  },
  {
    to: ROUTES.TASKS,
    label: 'Tasks',
  },
];
