import { ChangeEvent, FC, useRef } from 'react';

import { ReactComponent as DownloadIcon } from 'assets/icons/Download.svg';

import classes from './ImportFileIcon.module.css';

interface IProps {
  title: string;
  onUploadFile: (file: Blob) => void;
  key?: string;
}
const ImportFileIcon: FC<IProps> = ({ title, onUploadFile, key }) => {
  const uploadInput = useRef<null | HTMLInputElement>(null);
  const handleUploadFile = async (event: ChangeEvent<HTMLInputElement>) => {
    onUploadFile((event.target.files as FileList)[0]);
  };
  const onClickFile = () => {
    uploadInput.current?.click();
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div key={key} className={classes.FileUploadBlock} onClick={onClickFile}>
      <input
        ref={uploadInput}
        className={classes.FileUploadInput}
        type="file"
        onChange={handleUploadFile}
      />
      <DownloadIcon />
      <span>{title}</span>
    </div>
  );
};

export default ImportFileIcon;
