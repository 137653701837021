import { IColumn } from 'react-ui-kit-exante';

import { Transaction } from './types';

export const COLUMNS: IColumn<Transaction>[] = [
  {
    Header: 'Amount',
    accessor: 'amount',
    type: 'numberRange',
    width: 60,
  },
  {
    Header: 'Account id',
    accessor: 'account_id',
    type: 'textInput',
    width: 100,
  },
  {
    Header: 'Symbol',
    accessor: 'symbol',
    type: 'textInput',
    width: 60,
  },
  {
    Header: 'Asset',
    accessor: 'asset',
    type: 'textInput',
    width: 60,
  },
  {
    Header: 'Average price',
    accessor: 'average_price',
    type: 'numberRange',
    width: 100,
  },
  {
    Header: 'Legal entity',
    accessor: 'legal_entity',
    type: 'textInput',
    width: 100,
  },
  {
    Header: 'Ex date',
    accessor: 'ex_date',
    width: 100,
  },
  {
    Header: 'Tax uuid',
    accessor: 'tx_uuid',
    type: 'textInput',
    width: 80,
  },
  {
    Header: 'Parent uuid',
    accessor: 'parent_uuid',
    type: 'textInput',
    width: 80,
  },
  {
    Header: 'Event uuid',
    accessor: 'event_uuid',
    type: 'textInput',
    width: 80,
  },
];
