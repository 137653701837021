export type TOptionField = {
  [name: string]: string;
};

export type UpdateEventPayload = Record<
  string,
  boolean | string | Record<string, string | boolean>
>;

export interface CorporateActionInputForm {
  ca_type?: string;
  status?: string;
  security_id?: string;
  isin?: string;
  has_choice: boolean;
  bo_symbol?: string;
  effective_date: Date;
}

export enum EvenStatuses {
  EVENT_ERROR = 'Event Error',
  UPCOMING = 'Upcoming',
  POSTING = 'Posting',
  DONE = 'Done',
  FAILED = 'Failed',
  DELETED = 'Deleted',
  NO_CLIENTS = 'No Clients',
  AUTO_DONE = 'Auto Done',
  PENDING = 'Pending',
  WAITING = 'Waiting',
  APPROVED = 'Approved',
  MANUAL = 'Manual',
  REVERSED = 'Reversed',
  POSTING_ERROR = 'Posting Error',
}

export interface FetchedColumn {
  name: string;
  value: string;
  filter: boolean;
  sort: boolean;
  type: 'textInput' | 'select' | 'dateRange' | 'checkbox';
  options?: string[];
}

export type EventTypes = Record<string, string[]>;
