import { FC, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  PanelHeader,
  Panel,
  IconButton,
  Input,
  styled,
} from 'react-ui-kit-exante';

import { IVoteResult } from '../types';

import VoteResultsFormStyles from './VoteResultsForm.module.css';
import { IVoteResultsForm } from './VoteResultsForm.types';

const StyledVoteResultsForm = styled('form')(({ theme }) => ({
  backgroundColor: theme?.color.bg.primary,
  height: 'fit-content',
}));

export const VoteResultsForm: FC<IVoteResultsForm> = ({
  onCancel,
  valueForEdit,
}) => {
  const { control, handleSubmit, reset } = useForm<IVoteResult>({
    defaultValues: valueForEdit,
  });

  useEffect(() => {
    reset(valueForEdit);
  }, [valueForEdit]);

  return (
    <StyledVoteResultsForm onSubmit={handleSubmit(() => {})}>
      <PanelHeader title={valueForEdit.id}>
        <div className={VoteResultsFormStyles.Controls}>
          <IconButton iconName="CloseIcon" onClick={onCancel} />
        </div>
      </PanelHeader>
      <Panel className={VoteResultsFormStyles.PanelBody}>
        <div className={VoteResultsFormStyles.FormBody}>
          {(Object.keys(valueForEdit) as Array<keyof IVoteResult>).map(
            (name) => {
              return (
                <div key={name}>
                  <Controller
                    name={name}
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Input
                        label={name}
                        fullWidth
                        disabled
                        type="text"
                        {...field}
                      />
                    )}
                  />
                </div>
              );
            },
          )}
        </div>
      </Panel>
    </StyledVoteResultsForm>
  );
};
