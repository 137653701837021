import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { TOption, TSetting } from './Settings.types';

export const additionalColumnName = 'country_name';

export const getColumns = (columns: TOption['columns']): IColumn<TSetting>[] =>
  columns.map((name) => ({
    Header: name,
    accessor: name,
    Cell: (row: ICellValue<any>) => row.value ?? '-',
    align: 'left',
    className: '',
    minWidth: name === additionalColumnName ? 300 : 150,
  }));
