export const defaultActionTitle = 'Need to select rows';
export const displayedColumnsForDividends = [
  'ca_type',
  'bo_symbol',
  'isin',
  'description',
  'announce_date',
  'effective_date',
  'record_date',
  'pay_date',
  'gross_amount',
  'currency',
  'calculated_transactions',
  'posted_transactions',
  'status',
  'actions',
];
