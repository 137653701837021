export enum FieldType {
  string = 'string',
  bool = 'bool',
}

export interface DetailItem {
  value: string;
  name: string;
  parentName: string;
  fieldType: FieldType;
  readonly: boolean;
}
