import { corpActionsInstanceApi } from '../../api';
import { getErrorMessage } from '../../api/helpers';

export async function uploadEventsFile(payloadFile: Blob) {
  const formData = new FormData();
  formData.append('table', payloadFile);
  try {
    const response = await corpActionsInstanceApi.post({
      endPoint: `/ftp/file`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response;
  } catch (err: any) {
    return getErrorMessage(err);
  }
}
