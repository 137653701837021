import { FC, useEffect, useState } from 'react';
import { Table, Notification } from 'react-ui-kit-exante';

import { defaultPageSize, pageSizes } from 'consts';
import { fetchVoteResults } from 'services/CorporateActions/election';
import { getElectionDetails } from 'services/CorporateActions/elections';

import styles from './VoteResults.module.css';
import { VoteResultsForm } from './VoteResultsForm/VoteResultsForm';
import { COLUMNS } from './consts';
import { IVoteResult, IVoteResultsProps, TVoteResultsValue } from './types';

export const VoteResults: FC<IVoteResultsProps> = ({ id }) => {
  const [data, setData] = useState<IVoteResult[]>([]);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [, setIsShowNewVoteResultsForm] = useState<boolean>(false);
  const [valueForEdit, setValueForEdit] = useState<TVoteResultsValue>(null);

  const getElectionId = async (caId: number) => {
    setIsTableLoading(true);
    try {
      const result = await getElectionDetails(caId);
      const [election] = result;
      return election.id;
    } catch (err) {
      console.error(err);
      setIsTableLoading(false);
      return null;
    }
  };

  const getVoteResults = async (electionId: number) => {
    try {
      const response = await fetchVoteResults({
        electionId,
      });
      setData(response);
    } catch (error: any) {
      Notification.error({
        title: error?.message,
      });
    } finally {
      setIsTableLoading(false);
    }
  };

  const closeNewVoteResultsForm = () => {
    setValueForEdit(null);
    setIsShowNewVoteResultsForm(false);
  };

  const openNewVoteResultsForm = () => {
    setIsShowNewVoteResultsForm(true);
  };

  const onEditVoteResults = (values: TVoteResultsValue) => {
    setValueForEdit(values);
    openNewVoteResultsForm();
  };

  useEffect(() => {
    (async () => {
      const electionId = await getElectionId(id);
      if (electionId) {
        getVoteResults(electionId);
      }
    })();
  }, [id]);

  return (
    <div className={styles.Body}>
      <Table
        className={styles.Table}
        tableId="cas-vote-results"
        columns={COLUMNS}
        data={data}
        hasPagination
        pageSizes={pageSizes}
        pageSize={defaultPageSize}
        showTableInfo
        handleRowClick={onEditVoteResults}
        isHiddenColumnSelect
        isLoading={isTableLoading}
        isFlexLayout
      />

      {valueForEdit && (
        <VoteResultsForm
          valueForEdit={valueForEdit}
          onCancel={closeNewVoteResultsForm}
        />
      )}
    </div>
  );
};
