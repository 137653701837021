const CA_BASE = '/corporate-actions';

const isRun = window.runUIhistoryContainer;

export const CA_PATH = `${isRun ? CA_BASE : ''}`;

export const ROUTES = {
  CORPORATE_ACTIONS: `${CA_PATH}/actions`,
  CORPORATE_ACTIONS_DETAILS: `${CA_PATH}/actions/:actionId`,
  NEW_CORPORATE_ACTION: `${CA_PATH}/new-corporate-action`,
  POSTINGS: `${CA_PATH}/postings`,
  CORPORATE_ACTION_SETTINGS: `${CA_PATH}/settings`,
  CORPORATE_ACTION_SCRIPTS: `${CA_PATH}/scripts`,
  TASKS: `${CA_PATH}/tasks`,
};
