import { apiService, createInstance } from './api.service';
import { CAS_APIV1_1 } from './constants';
import { getCorporateActionsBaseUrl } from './helpers';

const corpActionsInstance = createInstance();

const corpActionsInstanceApi = apiService(
  getCorporateActionsBaseUrl(),
  corpActionsInstance,
);
const corpActionsInstanceApiV1 = apiService(
  getCorporateActionsBaseUrl(CAS_APIV1_1),
  corpActionsInstance,
);

export { corpActionsInstanceApi, corpActionsInstanceApiV1 };
