/* eslint  no-param-reassign: 2 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CorporateActionDetail } from 'services/CorporateActions';

const initialState: { selectedAction: CorporateActionDetail | null } = {
  selectedAction: null,
};

const actionsSlice = createSlice({
  name: 'actions',
  initialState,
  reducers: {
    setSelectedAction: (
      state,
      action: PayloadAction<CorporateActionDetail>,
    ) => {
      state.selectedAction = action.payload;
    },
    cleanSelectedAction: (state) => {
      state.selectedAction = null;
    },
  },
});

export const { setSelectedAction, cleanSelectedAction } = actionsSlice.actions;
export default actionsSlice.reducer;
