import { capitalize } from 'lodash';

export const DETAILS_MAPPING = {
  ca_type: 'report type',
  description: 'name',
  is_delisted: 'delisted',
  is_changed: 'changed',
};

export const getDisplayName = (key: string) =>
  capitalize(
    DETAILS_MAPPING[key as keyof typeof DETAILS_MAPPING] ||
      key.replaceAll('_', ' '),
  );
