import { FC, useCallback, useMemo } from 'react';
import {
  IOnFetchArguments,
  ISortBy,
  Table,
  useTableData,
} from 'react-ui-kit-exante';

import { defaultOrdering, defaultPageSize, pageSizes } from 'consts';
import { fetchActionHistory, PaidEvent } from 'services/CorporateActions';
import { calculateCountOfPages } from 'utils';

import { getColumns } from './getColumns';

export const History: FC<{ uid: string }> = ({ uid }) => {
  const getHistory = useCallback(
    (props: IOnFetchArguments) =>
      fetchActionHistory({
        ...props,
        params: { ...props.params, event_uuid: uid },
      }),
    [],
  );

  const tableData = useMemo(
    () => ({
      saveViewParamsAfterLeave: true,
      data: { onFetch: getHistory },
      pagination: {
        getDefaultPagination: () => ({ limit: defaultPageSize, skip: 0 }),
      },
    }),
    [getHistory],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    setSorting,
  } = useTableData<{
    events: PaidEvent[];
    pagination: { total: number };
  }>(tableData);

  const pageCount = useMemo(
    () => calculateCountOfPages(data?.pagination?.total || 0, limit),
    [limit, data],
  );

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
    }),
    [filters, resetFilters],
  );

  const handleSorting = useCallback(
    (sortingArray: ISortBy[]) => {
      setSorting(sortingArray);
    },
    [setSorting],
  );
  const columns = useMemo(
    () => getColumns({ onFilter: setFilter, onRemove: removeFilter }),
    [setFilter, removeFilter],
  );

  return (
    <Table
      title="History"
      tableId="corporateActionHistory"
      showTableInfo
      isFlexLayout
      showScrollbar
      saveViewParamsAfterLeave
      data={data?.events ?? []}
      columns={columns}
      isLoading={isLoading}
      hasFilters
      filteringProps={filteringProps}
      manualSortBy
      onSort={handleSorting}
      defaultSortBy={defaultOrdering}
      hasPagination
      pageSizes={pageSizes}
      serverPaginationProps={{
        pageIndex: page,
        pageCount,
        pageSize: limit,
        total: data?.pagination.total || 0,
        setPage,
        setPageSize: setLimit,
      }}
    />
  );
};
