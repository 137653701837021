import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import actionsSlice from './actionsSlice';

export const commonReducer = combineReducers({
  actions: actionsSlice,
});
export const store = configureStore({
  reducer: commonReducer,
});
