import dayjs from 'dayjs';
import { capitalize } from 'lodash';

import { DATE_FORMAT, DATE_TIME_FORMAT } from './const';

export const transformVariantsToSelectOptions = (
  variants: string[] = [],
  capitalized = false,
) =>
  variants?.map((type) => ({
    label: capitalized ? capitalize(type) : type,
    value: type,
  }));

export const formatDate = (date: Date | null, time?: boolean) => {
  const format = time ? DATE_TIME_FORMAT : DATE_FORMAT;
  return date ? dayjs(date).format(format) : '';
};

export const parseDate = (date: string) => dayjs(date).toDate();

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};
