import axios, { AxiosInstance, AxiosPromise, Method, CancelToken } from 'axios';

import { Headers, Methods } from './constants';
import {
  paramsSerializer,
  getAccessTokenFromCookie,
  getSessionIdFromCookie,
} from './helpers';

axios.defaults.withCredentials = false;
axios.defaults.timeout = 150000;

type TOptions = {
  contentType?: string;
  data?: any;
  method?: Method;
  endPoint?: string;
  url?: string;
  headers?: any;
  sessionToken?: string;
  params?: any;
  cancelToken?: CancelToken;
};

export const apiService = (baseURL: string, service: AxiosInstance = axios) => {
  const getHeaders = (contentType: string, sessionToken?: string) => {
    const headers: any = {
      [Headers.CONTENT_TYPE]: contentType,
    };

    if (sessionToken) {
      headers[Headers.X_AUTH_SESSIONID] = sessionToken;
    }

    return headers;
  };

  const request: (options: TOptions) => AxiosPromise = ({
    contentType = 'application/json',
    endPoint = '',
    headers,
    method,
    sessionToken,
    url = baseURL,
    ...options
  }) =>
    service({
      url: `${url}${endPoint}`,
      method,
      headers: {
        ...getHeaders(contentType, sessionToken),
        ...headers,
      },
      ...options,
      paramsSerializer,
    });

  const get: (options: TOptions) => any = ({ data, ...options }) =>
    request({
      ...options,
      method: Methods.GET,
    });

  const head: (options: TOptions) => any = ({ data, ...options }) =>
    request({
      ...options,
      method: Methods.HEAD,
    });

  const post = (options: TOptions) =>
    request({ method: Methods.POST, ...options });
  const put = (options: TOptions) =>
    request({ method: Methods.PUT, ...options });
  const patch = (options: TOptions) =>
    request({ method: Methods.PATCH, ...options });

  return {
    delete: (options: TOptions) =>
      request({ method: Methods.DELETE, ...options }),
    get,
    post,
    put,
    patch,
    head,
  };
};

export const createInstance = (
  sessionId: string | boolean | undefined = getSessionIdFromCookie(),
  authToken: string | boolean | undefined = getAccessTokenFromCookie()
    ?.access_token,
) => {
  const headers: {
    [Headers.X_AUTH_SESSIONID]?: string;
    [Headers.AUTHORIZATION]?: string;
  } = {};

  if (sessionId && typeof sessionId === 'string') {
    headers[Headers.X_AUTH_SESSIONID] = sessionId;
  }
  if (authToken && typeof authToken === 'string') {
    headers[Headers.AUTHORIZATION] = `Bearer ${authToken}`;
  }

  return axios.create({ headers });
};
