export interface Script {
  base_fields: Field[];
  extend: Field[];
  id: number;
  name: string;
  task_name: string;
}

export interface ScriptSetupProps {
  script: Script;
}

export interface TransactionsTableProps {
  transactions: Transaction[];
  isLoading: boolean;
}

export interface Transaction {
  amount: number;
  average_price: number;
  account_id: string;
  symbol: string;
  asset: string;
  legal_entity: string;
  tx_uuid: string;
  parent_uuid: string;
  event_uuid: string;
  ex_date: Date;
}

export interface Field {
  field_name: string;
  optional: boolean;
  default: null | Record<string, unknown>;
  type: keyof typeof InputTypes;
}

export enum InputTypes {
  NUMERIC = 'number',
  INTEGER = 'number',
  VARCHAR = 'text',
  BOOLEAN = 'checkbox',
  DATE = 'date',
}
