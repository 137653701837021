import { CorporateActionDetail } from 'services/CorporateActions';

import { DetailItem, FieldType } from '../types';

const nonEditFields = ['id', 'uid', 'task_id'];
export const formatDetailObject = (details: CorporateActionDetail) =>
  Object.keys(details)
    .reduce((acc: DetailItem[], key) => {
      const detailValue = details[key as keyof typeof details] || null;
      if (detailValue && typeof detailValue === 'object') {
        return [
          ...acc,
          ...Object.keys(detailValue).map((subKey: string) => {
            return {
              parentName: key,
              name: subKey,
              value: detailValue[subKey],
              readonly: nonEditFields.includes(subKey),
              fieldType:
                typeof detailValue[subKey] === 'boolean'
                  ? FieldType.bool
                  : FieldType.string,
            };
          }),
        ];
      }
      return [
        ...acc,
        {
          name: key,
          value: detailValue as string,
          parentName: '',
          readonly: nonEditFields.includes(key),
          fieldType:
            typeof detailValue === 'boolean'
              ? FieldType.bool
              : FieldType.string,
        },
      ];
    }, [])
    .reduce(
      (acc: DetailItem[], item) =>
        acc.find((accItem) => accItem.name === item.name)
          ? acc
          : [...acc, item],
      [],
    );
