import { useState, SyntheticEvent } from 'react';
import { IColumn, Modal, ICellValue } from 'react-ui-kit-exante';

import { HistoryItem } from 'services/CorporateActions';
import { ColumnsWithFilters } from 'types';

const maxCellWidth = 500;
const oneSymbolWidth = 5;
export const getColumns = ({ onFilter, onRemove }: ColumnsWithFilters) => {
  const columns: IColumn<HistoryItem>[] = [
    {
      Header: 'id',
      accessor: 'id',
      disableFilters: true,
      minWidth: 60,
    },
    {
      Header: 'message',
      accessor: 'message',
      width: 120,
      minWidth: 120,
      Cell: ({ row }: ICellValue<HistoryItem>) => {
        const [isOpened, setIsOpened] = useState(false);
        const handleShowModal = (event: SyntheticEvent) => {
          event.preventDefault();
          setIsOpened(true);
        };
        const needToShowLink =
          (row.original.message?.length ?? 0) * oneSymbolWidth > maxCellWidth;
        return (
          <div>
            {needToShowLink ? (
              /* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions */
              <a href="" onClick={handleShowModal}>
                {row.original.message}
              </a>
            ) : (
              row.original.message
            )}
            <Modal
              title="Detail"
              onClose={() => setIsOpened(false)}
              isOpened={isOpened}
            >
              <div className="d-flex justify-content-center w-100">
                <p>{row.original.message}</p>
              </div>
            </Modal>
          </div>
        );
      },
      onFilter,
      onRemove,
    },
    {
      Header: 'task id',
      accessor: 'task_id',
      minWidth: 60,
      onFilter,
      onRemove,
    },
    {
      Header: 'task name',
      accessor: 'task_name',
      width: 90,
      minWidth: 90,
      onFilter,
      onRemove,
    },
    {
      Header: 'timestamp',
      accessor: 'timestamp',
      width: 100,
      minWidth: 100,
      disableFilters: true,
      disableSortBy: true,
    },
  ];

  return columns;
};
