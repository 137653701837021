import { useEffect, useState } from 'react';
import {
  SelectedListWithSearch,
  Notification,
  Panel,
  PanelGroup,
  IOption,
} from 'react-ui-kit-exante';

import { fetchScripts } from 'services/CorporateActions';

import ScriptManagementStyles from './ScriptManagement.module.css';
import { ScriptSetup } from './ScriptSetup';
import { getFormattedScriptName } from './helpers';
import { Script } from './types';

const CorporateActionScripts = () => {
  const [scripts, setScripts] = useState([]);
  const [selectedScript, setSelectedScript] = useState<Script>(scripts[0]);
  const getScripts = async () => {
    try {
      const response = await fetchScripts();
      setScripts(
        response.map((el: { task_name: string }, index: number) => ({
          id: `${el.task_name}_${index}`,
          name: getFormattedScriptName(el.task_name),
          ...el,
        })),
      );
    } catch (error: any) {
      Notification.error({
        title: error?.message,
      });
    }
  };
  useEffect(() => {
    getScripts();
  }, []);

  const hangleScriptSelection = (script: IOption) => {
    setSelectedScript(script as unknown as Script);
  };

  return (
    <>
      <Panel
        title={`Manage scripts${
          selectedScript ? `: ${selectedScript.name}` : ''
        }`}
      />
      <PanelGroup className={ScriptManagementStyles.PanelGroup}>
        <Panel className={ScriptManagementStyles.ScriptSelectList}>
          <SelectedListWithSearch
            selected={selectedScript ? selectedScript.id : ''}
            options={scripts}
            onListItemClick={hangleScriptSelection}
          />
        </Panel>
        {selectedScript && <ScriptSetup script={selectedScript} />}
      </PanelGroup>
    </>
  );
};

export default CorporateActionScripts;
