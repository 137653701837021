import { Notification } from 'react-ui-kit-exante';

import { corpActionsInstanceApi } from '../../api';

export async function getElectionDetails(id: number) {
  try {
    const result = await corpActionsInstanceApi.get({
      endPoint: `/elections?id=${id}`,
    });
    return result.data;
  } catch (error) {
    Notification.error({
      title: 'Get election details error',
      description: String(error),
    });
  }
  return null;
}
