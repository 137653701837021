import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import {
  styled,
  Input,
  Notification,
  Panel,
  Modal,
  IconButton,
} from 'react-ui-kit-exante';

import {
  removeSettings,
  setSettings,
} from 'services/CorporateActions/settings';

import styles from './SettingForm.module.css';
import {
  FormValues,
  IAddSettingsForm,
  SuccessMessages,
} from './SettingsForm.types';

const StyledSettingsForm = styled('div')(({ theme }) => ({
  backgroundColor: theme?.color.bg.primary,
  height: 'fit-content',
}));

export const SettingForm: FC<IAddSettingsForm> = ({
  section,
  group,
  onCancel,
  onUpdateSettingsPage,
  valueForEdit,
  isNewSetting,
}) => {
  const [removeModalIsOpened, setRemoveModalIsOpened] = useState(false);
  const defaultFields = useMemo(
    () =>
      group.columns.map((settingParam) => ({
        [settingParam]: valueForEdit?.[settingParam] ?? '',
      })),
    [valueForEdit],
  );

  const { control, handleSubmit, reset, watch } = useForm<FormValues>({
    defaultValues: {
      setting: defaultFields,
    },
  });

  const { fields } = useFieldArray({
    control,
    name: 'setting',
  });

  useEffect(() => {
    reset({ setting: defaultFields });
  }, [defaultFields]);

  const onSave = async (values: FormValues) => {
    const options = values.setting
      .flatMap((i) => Object.values(i))
      .map((item) => (item === '' ? null : item));
    try {
      const response = await setSettings({
        name: section,
        section: group?.value,
        options,
      });
      if (response) {
        Notification.success({
          title: valueForEdit ? SuccessMessages.Update : SuccessMessages.Create,
        });
        onUpdateSettingsPage();
        onCancel();
      }
    } catch (error: any) {
      Notification.error({
        title: error?.message,
      });
    }
  };

  const handleRemove = () => {
    setRemoveModalIsOpened(true);
  };
  const handleConfirmRemove = async () => {
    const options = defaultFields
      .flatMap((i) => Object.values(i))
      .map((item) => (item === '' ? null : item));
    await removeSettings(
      {
        name: section,
        section: group?.value,
        options,
      },
      () => {
        onUpdateSettingsPage();
        onCancel();
      },
    );
  };

  const isLastField = useCallback(
    (index: number) => index === fields.length - 1 || fields.length <= 1,
    [fields],
  );

  const errors = useMemo(
    () =>
      watch().setting.reduce((acc: Record<string, boolean>, item, index) => {
        const fieldName = Object.keys(item)[0];
        const fieldValue = Object.values(item)[0];
        const condition =
          watch().setting.length - 1 !== index || watch().setting.length <= 1;
        return { ...acc, [fieldName]: condition ? !fieldValue : false };
      }, {}),
    [watch()],
  );

  const saveButtonIsDisabled = useMemo(
    () => Object.values(errors).some((item) => item),
    [errors],
  );

  return (
    <StyledSettingsForm>
      <Panel
        title={group.value}
        action={
          <div className={styles.Controls}>
            <IconButton
              iconSize={24}
              iconName="SaveIcon"
              disabled={saveButtonIsDisabled}
              onClick={handleSubmit(onSave)}
              data-test-id="setting-form-module__button--save"
            />
            <IconButton
              iconSize={24}
              iconName="CloseIcon"
              onClick={onCancel}
              data-test-id="setting-form-module__button--close-form"
            />
            {!isNewSetting && (
              <IconButton
                iconSize={24}
                iconName="DeleteIcon"
                onClick={handleRemove}
                data-test-id="setting-form-module__button--delete-setting"
              />
            )}
          </div>
        }
      />
      <Panel className={styles.PanelBody}>
        <div className={styles.FormBody}>
          {fields.map((item, index) => {
            const name = group.columns[index];
            return (
              <div key={item.id}>
                <Controller
                  name={`setting.${index}.${name}`}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      label={name}
                      error={errors[name]}
                      required={!isLastField(index)}
                      type="text"
                      {...field}
                    />
                  )}
                />
              </div>
            );
          })}
        </div>
      </Panel>
      <Modal
        isOpened={removeModalIsOpened}
        title="Remove setting"
        onClose={() => setRemoveModalIsOpened(false)}
        confirmButton={{
          confirmButtonName: 'Confirm',
          handleConfirm: handleConfirmRemove,
        }}
      >
        <p>Are you sure?</p>
      </Modal>
    </StyledSettingsForm>
  );
};
