import cn from 'classnames';
import { capitalize } from 'lodash';
import { useState, useEffect, useMemo } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import {
  Notification,
  IconButton,
  Input,
  Panel,
  DatePicker,
  Checkbox,
  Modal,
  useModal,
  styled,
} from 'react-ui-kit-exante';

import { runScript, TFormValues } from 'services/CorporateActions';

import ScriptSetupStyles from './ScriptManagement.module.css';
import { TransactionsTable } from './TransactionsTable';
import { Field, InputTypes, ScriptSetupProps } from './types';

const WarningPanelContent = styled('div')(({ theme }) => ({
  backgroundColor: theme?.color.bg.primary,
  marginTop: '8px',
  display: 'flex',
  padding: '12px 24px',
  alignItems: 'center',
  justifyContent: 'space-between',
  lineHeight: '24px',
}));
const controlCls = { mt: '16px', mr: '16px', width: '280px' };

export const ScriptSetup = ({ script }: ScriptSetupProps) => {
  const defaultValues = useMemo(
    () =>
      script.extend.reduce(
        (acc, field) => ({ ...acc, [field.field_name]: null }),
        { ex_date: new Date() },
      ),
    [script],
  );

  const [isDry, setIsDry] = useState(true);
  const [showMsg, setShowMsg] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const { isOpened, onClose, onToggle } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [isPosted, setIsPosted] = useState(false);
  const methods = useForm<TFormValues>({ defaultValues });

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
    getValues,
  } = methods;

  useEffect(() => {
    if (!isDry) {
      setIsDry(true);
    }

    reset(defaultValues);
    setTransactions([]);
  }, [script]);

  const actionMessage = isDry ? `dry run` : `post`;
  const values = getValues();

  const modalContent = useMemo(
    () => (
      <>
        <div>You are going to {actionMessage} with following params:</div>
        <div>
          {Object.entries(values).map(([key, value]) => (
            <div key={key}>
              {value && (
                <>
                  <span>{key}</span>: <span>{value.toString()}</span>
                </>
              )}
            </div>
          ))}
        </div>
      </>
    ),
    [actionMessage, values],
  );

  const handleRunScript = async (
    params: Record<string, string | Date | boolean | null>,
  ) => {
    try {
      setIsLoading(true);
      setShowMsg(true);
      const response = await runScript(script.task_name, params, isDry);
      if (response) {
        if (isDry) {
          setTransactions(response);
          setIsDry(false);
        } else {
          setIsPosted(true);
        }
        setShowMsg(false);
      }
    } catch (error: any) {
      Notification.error({
        title: error?.message,
      });
      setShowMsg(false);
    } finally {
      setIsLoading(false);
      setTimeout(() => setShowMsg(false), 3000);
    }
  };

  const handleConfirm = () => {
    onClose();
    handleSubmit(handleRunScript)();
  };

  const handleReset = () => {
    setIsDry(true);
    setIsPosted(false);
    reset();
    setTransactions([]);
  };

  const sortedScriptsParams = [...script.extend].sort(
    (scriptParam1: Field, scriptParam2: Field) =>
      scriptParam1.optional && !scriptParam2.optional ? 1 : -1,
  );

  const warningPanelCls = cn(ScriptSetupStyles.WarningPanel, {
    [ScriptSetupStyles.WarningPanelFull]: !showMsg,
  });

  const panelMsg = isDry
    ? 'The data is currently being downloaded, which can take up to several\n minutes.'
    : 'Your request was sent successfully, processing may takes time.';

  return (
    <div>
      <Panel>
        <div className={ScriptSetupStyles.Buttons}>
          <IconButton
            iconName="PlayIcon"
            disabled={!isDry}
            type="button"
            onClick={handleSubmit(onToggle)}
            label="Dry run mode"
          />
          <IconButton
            iconName="SaveIcon"
            type="button"
            disabled={isDry || !isDirty || isPosted}
            onClick={handleSubmit(onToggle)}
            label="Posting"
          />
          <IconButton
            iconName="CloseIcon"
            disabled={isDry}
            type="button"
            onClick={handleReset}
            label="Reset"
          />
        </div>
      </Panel>
      <div className={warningPanelCls}>
        <WarningPanelContent>
          {panelMsg}
          <IconButton
            iconName="CloseIcon"
            onClick={() => {
              setShowMsg(false);
            }}
          />
        </WarningPanelContent>
      </div>
      <div className={ScriptSetupStyles.Container}>
        <Panel className={ScriptSetupStyles.Setup}>
          <FormProvider {...methods}>
            <Controller
              name="ex_date"
              control={control}
              key={`${script.task_name}ex_date`}
              render={({ field: { ref, ...rest } }) => (
                <DatePicker
                  id="ex_date"
                  label="ex_date"
                  controlProps={{
                    required: true,
                  }}
                  {...rest} // because DatePicker not support ref
                  value={(rest.value as Date) || new Date()}
                />
              )}
            />
            {sortedScriptsParams.map((scriptParam: Field) => {
              if (InputTypes[scriptParam.type] === InputTypes.BOOLEAN) {
                return (
                  <Controller
                    name={scriptParam.field_name}
                    control={control}
                    key={script.task_name + scriptParam.field_name}
                    render={({ field }) => (
                      <Checkbox
                        label={scriptParam.field_name}
                        sx={controlCls}
                        {...field}
                        checked={field.value as boolean}
                      />
                    )}
                  />
                );
              }

              if (InputTypes[scriptParam.type] === InputTypes.DATE) {
                return (
                  <Controller
                    name={scriptParam.field_name}
                    control={control}
                    key={script.task_name + scriptParam.field_name}
                    render={({ field }) => (
                      <DatePicker
                        label={scriptParam.field_name}
                        required={!scriptParam.optional}
                        controlProps={{
                          sx: controlCls,
                        }}
                        {...field}
                        value={field.value as Date}
                      />
                    )}
                  />
                );
              }

              return (
                <Controller
                  name={scriptParam.field_name}
                  control={control}
                  key={script.task_name + scriptParam.field_name}
                  rules={{ required: !scriptParam.optional }}
                  render={({ field, fieldState }) => (
                    <Input
                      error={!!fieldState.error}
                      label={scriptParam.field_name}
                      required={!scriptParam.optional}
                      sx={controlCls}
                      {...field}
                      value={field.value || ''}
                    />
                  )}
                />
              );
            })}
          </FormProvider>
        </Panel>
        <TransactionsTable isLoading={isLoading} transactions={transactions} />
        <Modal
          isOpened={isOpened}
          onClose={onClose}
          title={capitalize(actionMessage)}
          confirmButton={{
            confirmButtonName: 'Confirm',
            handleConfirm,
          }}
        >
          {modalContent}
        </Modal>
      </div>
    </div>
  );
};
