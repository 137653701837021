import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { isEmpty, uniqueId } from 'lodash';
import React, { useState } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  SubmitHandler,
} from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
  DatePicker,
  Input,
  Switch,
  Select,
  Notification,
  IconButton,
  styled,
} from 'react-ui-kit-exante';

import {
  addNewCorporateAction,
  CorporateActionInputForm,
} from 'services/CorporateActions';
import { FormHeader } from 'shared/Form/FormHeader';
import { DATE_FORMAT } from 'shared/const';

import styles from './NewCorporateAction.module.css';
import { CorporateActionTypeOptions } from './const';

type FormPayload = Omit<CorporateActionInputForm, 'effective_date'>;

export const Form = styled('div')(({ theme }) => ({
  backgroundColor: theme?.color.bg.primary,
}));

const NewCorporateAction = () => {
  const history = useHistory();

  const form = useForm<FormPayload>();
  const {
    control,
    handleSubmit,
    unregister,
    formState: { isSubmitting, isDirty },
  } = form;

  const [dateField, setDateField] = useState('');
  const handleChangeDate = (date: unknown) => {
    const dateObject = dayjs(date as Date);
    setDateField(dateObject.format(DATE_FORMAT));
  };

  const [optionalFields, setOptionalFields] = useState<
    { name: string; value: string; id: string }[]
  >([]);

  const addNewOptionalField = () => {
    setOptionalFields([
      ...optionalFields,
      { name: '', value: '', id: uniqueId() },
    ]);
  };

  const handleOptionalFieldChange = (
    value: string,
    modificator: 'name' | 'value',
    fieldId: number,
  ) => {
    const newOptionalFields = [...optionalFields];
    newOptionalFields[fieldId][modificator] = value;
    setOptionalFields([...newOptionalFields]);
  };

  const deleteOptionalField = (fieldId: number) => {
    const newOptionalFields = [...optionalFields];
    newOptionalFields.splice(fieldId, 1);
    setOptionalFields([...newOptionalFields]);
    if (optionalFields[fieldId].name) {
      unregister(optionalFields[fieldId].name as keyof FormPayload);
    }
  };

  const handleClose = () => {
    history.goBack();
  };

  const handleSave: SubmitHandler<FormPayload> = async (
    values: FormPayload,
  ) => {
    const payload: CorporateActionInputForm = {
      ...values,
      effective_date: dateField,
    };
    try {
      await addNewCorporateAction(payload);
      Notification.success({ title: 'Was created' });
      handleClose();
    } catch (e: unknown) {
      const { response } = e as AxiosError;
      Notification.error({
        title:
          (response?.data as Record<string, string>).message ||
          'Unhandled error',
      });
    }
  };
  return (
    <FormProvider {...form}>
      <Form onSubmit={handleSubmit(handleSave)}>
        <FormHeader
          isSubmitting={isSubmitting}
          isDirty={isDirty}
          handleClose={handleClose}
          text="Create new CA"
        />
        <div className={styles.FormBody}>
          <div className={styles.RequiredFields}>
            <Controller
              name="ca_type"
              control={control}
              render={({ field }) => (
                <Select
                  className={styles.Input}
                  id="ca_type"
                  placeholder="Report type"
                  required
                  sx={{ minWidth: 195, marginRight: '16px' }}
                  data-test-id="new-ca-module__select--ca-type"
                  {...field}
                  options={CorporateActionTypeOptions}
                />
              )}
            />
            <Controller
              name="security_id"
              control={control}
              render={({ field }) => (
                <Input
                  id="security_id"
                  label="Security id"
                  required
                  fullWidth
                  data-test-id="new-ca-module__input--security-id"
                  {...field}
                  sx={{ marginRight: '16px' }}
                />
              )}
            />
            <DatePicker
              name="Effective date"
              label="Effective date"
              data-test-id="new-ca-module__input--effective-date"
              controlProps={{ fullWidth: true, required: true }}
              value={dateField || null}
              onChange={handleChangeDate}
            />
            <Controller
              name="isin"
              control={control}
              render={({ field }) => (
                <Input
                  id="isin"
                  label="ISIN"
                  required
                  data-test-id="new-ca-module__input--isin"
                  sx={{ marginLeft: '16px', marginRight: '16px' }}
                  {...field}
                />
              )}
            />
            <Controller
              name="bo_symbol"
              control={control}
              render={({ field }) => (
                <Input
                  id="bo_symbol"
                  label="Bo symbol"
                  required
                  data-test-id="new-ca-module__input--bo-symbol"
                  sx={{ marginRight: '16px' }}
                  {...field}
                />
              )}
            />
            <Controller
              name="has_choice"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <Switch
                  color="primary"
                  id="has_choice"
                  label="Has choice"
                  labelPlacement="start"
                  data-test-id="new-ca-module__switch--has_choice"
                  sx={{ marginRight: '16px' }}
                  {...field}
                />
              )}
            />
          </div>
          {!isEmpty(optionalFields) &&
            optionalFields.map((optField, index) => (
              <div className={styles.OptionalFields} key={optField.id}>
                <Input
                  id="name"
                  label="Field name"
                  value={optField.name}
                  required
                  onChange={(event: any) => {
                    handleOptionalFieldChange(
                      event.target.value,
                      'name',
                      index,
                    );
                  }}
                  data-test-id="new-ca-module__input--opt-field-name"
                />
                <Controller
                  name={optField.name as keyof FormPayload}
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="value"
                      label="Value"
                      required
                      data-test-id="new-ca-module__input--opt-field-name"
                      {...field}
                      onChange={(event: any) => {
                        handleOptionalFieldChange(
                          event.target.value,
                          'value',
                          index,
                        );
                        field.onChange(event);
                      }}
                    />
                  )}
                />
                <IconButton
                  iconSize={24}
                  iconName="DeleteIcon"
                  onClick={() => {
                    deleteOptionalField(index);
                  }}
                  data-test-id="new-ca-module__button--delete-opt-field"
                />
              </div>
            ))}

          <IconButton
            label="Add new field"
            iconSize={24}
            iconName="AddIcon"
            onClick={addNewOptionalField}
            data-test-id="new-ca-module__button--add-opt-field"
          />
        </div>
      </Form>
    </FormProvider>
  );
};

export default NewCorporateAction;
