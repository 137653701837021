import { TCommonParams } from 'react-ui-kit-exante';

export interface SortingTableObject {
  id: string;
  desc: boolean;
}

export const calculateCountOfPages = (total: number, limit: number) =>
  Math.ceil(total / limit);

export const getSortingParams = (sorting: TCommonParams['sorting']) =>
  (sorting as SortingTableObject[]).reduce((acc, item) => {
    const noEmpty = !!Object.values(item).length;
    return noEmpty
      ? { order: item.desc ? 'desc' : 'asc', order_by: item.id }
      : {};
  }, {});

export const getParsedTableParamsById = (id: string) =>
  JSON.parse(localStorage.getItem(`${id}-view-params`) ?? '{}');
