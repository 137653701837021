import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { styled } from 'react-ui-kit-exante';

import HeaderStyles from './Header.module.css';
import { NAV_LINKS } from './constants';

const HeaderStyled = styled('header')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  backgroundColor: theme?.palette?.success?.main,
  position: 'relative',
  padding: '0 24px',
  boxSizing: 'border-box',
}));

const HeaderTitleStyled = styled('span')(({ theme }) => ({
  fontSize: '22px',
  color: theme?.color.bg.primary,
}));

const HeaderLinkStyled = styled(NavLink)(({ theme }) => ({
  display: 'inline-block',
  textDecoration: 'none',
  color: theme?.color.bg.primary,
  padding: '16px',
  '&.active': {
    backgroundColor: theme?.color.bg.primary,
    color: theme?.color.typo.action,
    opacity: 0.9,
  },
}));

export const Header: FC = () => {
  return (
    <HeaderStyled>
      <HeaderTitleStyled>Corporate actions</HeaderTitleStyled>
      <ul className={HeaderStyles.List}>
        {NAV_LINKS.map(({ to, label }) => (
          <li key={to}>
            <HeaderLinkStyled to={to}>{label}</HeaderLinkStyled>
          </li>
        ))}
      </ul>
    </HeaderStyled>
  );
};
