import { useCallback } from 'react';

export function useSelectRows<T extends { id: number }>({
  setSelectedItems,
  selectedItems,
  items,
}: {
  selectedItems: T[];
  setSelectedItems: (items: T[]) => void;
  items: T[];
}) {
  const handleCheckItem = useCallback(
    (item: T) => {
      if (selectedItems.find((i) => i.id === item.id)) {
        setSelectedItems([...selectedItems.filter((i) => i.id !== item.id)]);
      } else {
        setSelectedItems([...selectedItems, item]);
      }
    },
    [selectedItems, setSelectedItems],
  );

  const handleCheckAllOnPage = useCallback(() => {
    if (items.length) {
      setSelectedItems([...selectedItems, ...items]);
    }
  }, [setSelectedItems, items]);

  const handleUnCheckAllOnPage = useCallback(() => {
    const withoutItemsOnPage = selectedItems.filter((item) => {
      const foundItem = items.find((i) => i.id === item.id);
      return !foundItem;
    });
    setSelectedItems(withoutItemsOnPage);
  }, [setSelectedItems, selectedItems, items]);

  return {
    handleCheckItem,
    handleCheckAllOnPage,
    handleUnCheckAllOnPage,
  };
}
