import { ICellValue } from 'react-ui-kit-exante';

import { getCellCheckbox, getGeneralCheckbox } from 'components';
import { ExpandedCorpAction } from 'services/CorporateActions';
import { getTableColumns } from 'utils/getTableColumns';

import { IGetColumns } from './types';

export const getColumns = ({
  onFilter,
  onRemove,
  rows,
  selectedRowsIds,
  onCheckAllRowsOnPage,
  onUnCheckAllRowsOnPage,
  onCheckRow,
  columnsList,
  eventTypes,
}: IGetColumns) => [
  {
    accessor: 'checking',
    minWidth: 70,
    width: 70,
    Header: () =>
      Array.isArray(rows)
        ? getGeneralCheckbox(
            rows,
            selectedRowsIds,
            onCheckAllRowsOnPage,
            onUnCheckAllRowsOnPage,
            (action) => action.id,
          )
        : '',

    Cell: ({ row }: ICellValue<ExpandedCorpAction>) => {
      return Array.isArray(rows)
        ? getCellCheckbox(
            row.original.id,
            rows,
            selectedRowsIds,
            onCheckRow,
            (action) => action.id,
          )
        : '';
    },
    disableSortBy: true,
    disableFilters: true,
    className: 'd-block',
  },
  ...getTableColumns({
    fetchedColumns: columnsList.filter((column) =>
      eventTypes?.find((type) =>
        column.events_type?.find((eventType) => eventType === type),
      ),
    ),
    onFilter,
    onRemove,
  }),
];
