import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, Route, Router, Switch } from 'react-router-dom';
import { NotificationProvider, styled } from 'react-ui-kit-exante';

import { ErrorFallback } from 'components/ErrorFallback';
import { Header } from 'components/Header/Header';
import { CorporateActions } from 'pages/CorporateActions';
import { CorporateActionDetails } from 'pages/CorporateActions/CorporateActionDetails/CorporateActionDetails';
import NewCorporateAction from 'pages/NewCorporateAction/NewCorporateAction';
import Postings from 'pages/Postings/Postings';
import CorporateActionScripts from 'pages/ScriptsManagement/ScriptList';
import Settings from 'pages/Settings/Settings';
import Tasks from 'pages/Tasks/Tasks';
import { ROUTES } from 'routes';
import { getBaseName } from 'utils/getBaseName';

import styles from './Application.module.css';

const AppVersionStyled = styled('div')(({ theme }) => ({
  padding: '20px',
  color: theme?.palette.text.disabled,
  fontSize: '11px',
}));

export const Application = () => {
  const history = window.runUIhistoryContainer;

  const routes = (
    <Switch>
      <Route exact path={ROUTES.CORPORATE_ACTIONS}>
        <CorporateActions />
      </Route>
      <Route path={ROUTES.CORPORATE_ACTIONS_DETAILS}>
        <CorporateActionDetails />
      </Route>
      <Route path={ROUTES.NEW_CORPORATE_ACTION}>
        <NewCorporateAction />
      </Route>
      <Route path={ROUTES.CORPORATE_ACTION_SETTINGS}>
        <Settings />
      </Route>
      <Route path={ROUTES.CORPORATE_ACTION_SCRIPTS}>
        <CorporateActionScripts />
      </Route>
      <Route path={ROUTES.POSTINGS}>
        <Postings />
      </Route>
      <Route path={ROUTES.TASKS}>
        <Tasks />
      </Route>
    </Switch>
  );
  return window.runUIhistoryContainer ? (
    <Router history={history}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <NotificationProvider />
        {routes}
      </ErrorBoundary>
    </Router>
  ) : (
    <BrowserRouter basename={getBaseName()}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <NotificationProvider />
        <Header />
        <div className={styles.Container}>{routes}</div>
        <AppVersionStyled>
          Version {process?.env?.REACT_APP_VERSION}
        </AppVersionStyled>
      </ErrorBoundary>
    </BrowserRouter>
  );
};
