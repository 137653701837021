import { Table } from 'react-ui-kit-exante';

import { defaultPageSize, pageSizes } from '../../consts';

import ScriptManagementStyles from './ScriptManagement.module.css';
import { COLUMNS } from './const';
import { TransactionsTableProps } from './types';

export const TransactionsTable = ({
  isLoading,
  transactions,
}: TransactionsTableProps) => (
  <div className={ScriptManagementStyles.Transactions}>
    <Table
      data={transactions}
      columns={COLUMNS}
      tableId="Transactions"
      saveColumnOrder
      title="Transactions"
      isPinnedHeader
      hasPagination
      pageSizes={pageSizes}
      pageSize={defaultPageSize}
      showTableInfo
      isLoading={isLoading}
      hasFilters
      isFlexLayout
      exportTableParams={{
        type: 'client',
      }}
    />
  </div>
);
