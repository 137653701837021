import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import {
  Input,
  Checkbox,
  IconButton,
  Loader,
  Panel,
  Modal,
  useModal,
  Notification,
} from 'react-ui-kit-exante';

import { updateEvent, CorporateActionDetail } from 'services/CorporateActions';
import { UpdateEventPayload } from 'services/types';

import { getDisplayName } from '../utils';

import styles from './DetailedInformation.module.css';
import { FieldType } from './types';
import { formatDetailObject } from './utils';

interface IProps {
  details: CorporateActionDetail;
  isLoading: boolean;
}

export const DetailedInformation: FC<IProps> = ({ details, isLoading }) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const { isOpened, onClose, onOpen } = useModal();

  const [detailList, setDetailsList] = useState(formatDetailObject(details));
  useEffect(() => {
    setDetailsList(formatDetailObject(details));
  }, [details]);
  const getHandleChange =
    (parentName: string, name: string, fieldType: FieldType) =>
    (event: SyntheticEvent) => {
      const foundIndex = detailList.findIndex(
        (item) => item.name === name && item.parentName === parentName,
      );
      const target = event.target as any;
      const buffArray = [...detailList];
      if (foundIndex >= 0) {
        buffArray[foundIndex] = {
          ...buffArray[foundIndex],
          value: fieldType === FieldType.string ? target.value : target.checked,
        };
      }
      setDetailsList(buffArray);
    };

  const handleUpdateEvent = async () => {
    const payload: UpdateEventPayload = detailList.reduce(
      (acc: UpdateEventPayload, item) => {
        if (item.parentName) {
          return {
            ...acc,
            [item.parentName]: {
              ...(acc[item.parentName] as any),
              [item.name]: item.value,
            },
          };
        }
        return {
          ...acc,
          [item.name]: item.value,
        };
      },
      {},
    );
    try {
      setIsUpdating(true);
      await updateEvent(payload);
      onClose();
      Notification.success({ title: 'Event was updated' });
    } catch (error) {
      onClose();
      Notification.error({
        title: 'Update event error',
        description: String(
          (error as Record<string, Record<string, unknown>>)?.response?.data ??
            error,
        ),
      });
    } finally {
      setIsUpdating(false);
    }
  };

  const changedValues = useMemo(() => {
    const initialValues = formatDetailObject(details);
    return detailList
      .filter(
        (item) =>
          initialValues.find(
            (initialItem) =>
              initialItem.name + initialItem.parentName ===
              item.name + item.parentName,
          )?.value !== item.value,
      )
      .map((item) => ({
        name: getDisplayName(item.name),
        value: item.value,
      }));
  }, [details, detailList]);

  const panelAction =
    !!Object.values(details).length && isUpdating ? (
      <Loader />
    ) : (
      <IconButton
        data-test-id="ca-selection-module__button--save-selection"
        iconName="SaveIcon"
        label="Save"
        onClick={onOpen}
        disabled={!changedValues.length}
      />
    );

  const modalContent = useMemo(
    () => (
      <>
        <div>You are going to update this event params:</div>
        <div>
          {changedValues.map((item) => (
            <div key={item.name}>
              <p>
                <span>{item.name}</span>: <b>{item.value}</b>
              </p>
            </div>
          ))}
        </div>
      </>
    ),
    [changedValues],
  );
  //
  const sortedDetailList = useMemo(
    () =>
      [...detailList].sort((firstValue, secondValue) => {
        if (firstValue.name < secondValue.name) {
          return -1;
        }
        if (firstValue.name > secondValue.name) {
          return 1;
        }
        return 0;
      }),
    [detailList],
  );

  return isLoading ? (
    <div className={styles.LoaderBlock}>
      <Loader size="l" />
    </div>
  ) : (
    <>
      <Panel title="Edit details" action={panelAction}>
        <div className={styles.Details}>
          {sortedDetailList.map(
            ({ parentName, name, value, fieldType, readonly }) => (
              <div key={`${parentName}${name}`} className={styles.Detail}>
                {fieldType === FieldType.bool && (
                  <Checkbox
                    onChange={getHandleChange(
                      parentName ?? '',
                      name,
                      fieldType,
                    )}
                    label={getDisplayName(name)}
                    checked={!!value}
                  />
                )}
                {fieldType === FieldType.string && (
                  <Input
                    className={styles.RowInput}
                    onChange={getHandleChange(
                      parentName ?? '',
                      name,
                      fieldType,
                    )}
                    label={getDisplayName(name)}
                    disabled={readonly}
                    value={value ?? ''}
                  />
                )}
              </div>
            ),
          )}
        </div>
      </Panel>
      <Modal
        isOpened={isOpened}
        onClose={onClose}
        title="Are you sure?"
        confirmButton={{
          confirmButtonName: 'Confirm',
          handleConfirm: handleUpdateEvent,
        }}
      >
        {modalContent}
      </Modal>
    </>
  );
};
