import { IColumn } from 'react-ui-kit-exante';

import { IVoteResult } from './types';

export const COLUMNS: IColumn<IVoteResult>[] = [
  {
    Header: 'Id',
    accessor: 'id',
    width: 80,
    minWidth: 80,
  },
  {
    Header: 'Election ',
    accessor: 'election',
    width: 120,
    minWidth: 120,
  },
  {
    Header: 'Election ID',
    accessor: 'election_id',
    width: 90,
    minWidth: 90,
  },
  {
    Header: 'Account',
    accessor: 'account',
    width: 120,
    minWidth: 120,
  },
];
