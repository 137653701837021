import { TSettingValue, TOption } from '../../Settings.types';

export interface IAddSettingsForm {
  group: TOption;
  onCancel: VoidFunction;
  onUpdateSettingsPage: VoidFunction;
  valueForEdit?: TSettingValue;
  section: string;
  isNewSetting: boolean;
}

export type FormValues = {
  setting: {
    [key: string]: string;
  }[];
};

export enum SuccessMessages {
  Create = 'Successfully created',
  Update = 'Successfully updated',
}
