import { Notification } from 'react-ui-kit-exante';

import { corpActionsInstanceApi } from '../../api';
import { getErrorMessage } from '../../api/helpers';

export async function fetchVoteResults({ electionId }: { electionId: number }) {
  try {
    const result = await corpActionsInstanceApi.get({
      endPoint: `/election/result?id=${electionId}`,
    });
    return result.data;
  } catch (error) {
    Notification.error({
      title: 'Get vote results error',
      description: getErrorMessage(error),
    });
  }
  return null;
}

export async function getElectionFile(id: number) {
  try {
    const result = await corpActionsInstanceApi.get({
      endPoint: `/file/${id}`,
    });
    return result.data;
  } catch (err: any) {
    return getErrorMessage(err);
  }
}
