import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import {
  Table,
  Loader,
  Notification,
  IconButton,
  Panel,
  Tabs,
  Tab,
  styled,
} from 'react-ui-kit-exante';

import {
  fetchSettings,
  fetchSettingsGroups,
} from 'services/CorporateActions/settings';

import { defaultPageSize, pageSizes, countries } from '../../consts';

import { getColumns, additionalColumnName } from './Settings.helper';
import styles from './Settings.module.css';
import {
  TSettingValue,
  TOption,
  TSettings,
  SectionItem,
} from './Settings.types';
import { SectionSelect } from './components/SectionSelect/SectionSelect';
import { SettingForm } from './components/SettingForm/SettingForm';

const StyledDiv = styled('div')(() => ({
  border: `1px solid #ebebeb`,
  borderTop: '0',
  borderBottom: '0',
  display: 'flex',
}));

const getSectionByTabName = (tabName: string, sections: SectionItem[]) =>
  sections
    .filter((item) => item.tab === tabName)
    ?.map((item) => ({
      value: item.name,
      label: item.name,
      columns: item.list,
    }));

const Settings: FC = () => {
  const [selectedOption, setSelectedOption] = useState<TOption | null>(null);
  const [data, setData] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [isGroupsLoading, setIsGroupsLoading] = useState<boolean>(false);
  const [isShowNewSetting, setIsShowNewSetting] = useState<boolean>(false);
  const [valueForEdit, setValueForEdit] = useState<TSettingValue>(null);

  const [tabs, setTabs] = useState<string[]>([]);
  const [sections, setSections] = useState<SectionItem[]>([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const selectedTabName = useMemo(() => tabs[selectedTab], [tabs, selectedTab]);
  const options = useMemo(
    () => getSectionByTabName(selectedTabName, sections),
    [sections, selectedTabName],
  );

  const handleChangeTab = (event: ChangeEvent<unknown>, newValue: number) => {
    setSelectedTab(newValue);
    const filteredSections = getSectionByTabName(tabs[newValue], sections);
    setSelectedOption(filteredSections[0] ?? null);
  };
  const getSettings = async () => {
    try {
      setIsTableLoading(true);
      const response = await fetchSettings({
        name: selectedTabName,
        section: selectedOption?.value,
      });
      setData(
        response.map((item: any) => ({
          ...item,
          country_name: countries.find(
            (country) => item.country === country.code,
          )?.name,
        })),
      );
    } catch (error: any) {
      Notification.error({
        title: error?.message,
      });
    } finally {
      setIsTableLoading(false);
    }
  };

  const getSettingsGroups = async () => {
    try {
      setIsGroupsLoading(true);
      const response: TSettings[] = await fetchSettingsGroups();
      setTabs(response.map((item) => item.name));
      const resultSections = response.reduce(
        (arr: SectionItem[], item) => [
          ...arr,
          ...item.sections.map((section) => ({
            tab: item.name,
            name: section.name,
            list: section.list,
          })),
        ],
        [],
      );
      if (resultSections[0]) {
        setSelectedOption({
          value: resultSections[0].name,
          label: resultSections[0].name,
          columns: resultSections[0].list,
        });
      }
      setSections(resultSections);
    } catch (error: any) {
      Notification.error({
        title: error?.message,
      });
    } finally {
      setIsGroupsLoading(false);
    }
  };

  const tableColumns = useMemo(
    () =>
      getColumns(
        selectedOption?.columns.includes('country')
          ? [additionalColumnName, ...(selectedOption?.columns ?? [])]
          : selectedOption?.columns || [],
      ),
    [selectedOption?.columns],
  );

  const closeNewSettingForm = () => {
    setValueForEdit(null);
    setIsShowNewSetting(false);
  };

  const openNewSettingForm = () => {
    setIsShowNewSetting(true);
  };

  const onChooseSettingForEditing = (values: TSettingValue) => {
    setValueForEdit(values);
    openNewSettingForm();
  };

  const handleSelectGroup = (value: string | number) => {
    const option = options?.find((i) => i.value === value);
    setSelectedOption(option || null);
  };

  useEffect(() => {
    getSettingsGroups();
  }, []);

  useEffect(() => {
    if (selectedOption) {
      getSettings();
    }
    closeNewSettingForm();
  }, [selectedOption, selectedTab]);

  const addAction = (
    <div>
      {!isShowNewSetting && (
        <IconButton
          onClick={openNewSettingForm}
          disabled={!selectedOption}
          iconName="AddIcon"
          label="Add setting"
        />
      )}
    </div>
  );

  return (
    <Panel title="Settings" action={addAction}>
      <Tabs value={selectedTab} onChange={handleChangeTab}>
        {tabs.map((item) => (
          <Tab label={item} key={item} />
        ))}
      </Tabs>
      <StyledDiv>
        {isGroupsLoading ? (
          <Loader />
        ) : (
          <SectionSelect
            data-test-id="ca-settings__select--group"
            options={options}
            onSelect={handleSelectGroup}
            selectedValue={selectedOption?.value}
          />
        )}
        <div className={styles.Body}>
          {selectedOption?.columns && (
            <Table
              className={styles.Table}
              tableId={`settings-${selectedOption?.value}`}
              columns={tableColumns}
              data={data}
              displayedColumnKeys={tableColumns.map(
                (i) => i.accessor as string,
              )}
              saveColumnOrder
              handleRowClick={onChooseSettingForEditing}
              isLoading={isTableLoading}
              isPinnedHeader
              hasPagination
              pageSizes={pageSizes}
              pageSize={defaultPageSize}
              showTableInfo
              isHiddenColumnSelect
              isFlexLayout
            />
          )}
          {selectedOption && isShowNewSetting && (
            <SettingForm
              section={selectedTabName ?? ''}
              group={selectedOption}
              valueForEdit={valueForEdit}
              onCancel={closeNewSettingForm}
              onUpdateSettingsPage={getSettings}
              isNewSetting={!valueForEdit}
            />
          )}
        </div>
      </StyledDiv>
    </Panel>
  );
};

export default Settings;
