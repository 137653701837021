import { IOnFetchArguments, Notification } from 'react-ui-kit-exante';

import { getPayloadWithFormattedDates, getSortingParams } from 'utils';

import { corpActionsInstanceApi } from '../../api';
import { FetchedColumn } from '../../types';

import { ITask } from './types';

export async function fetchTaskFields() {
  try {
    const response = await corpActionsInstanceApi.get({
      endPoint: `/task-journal/fields`,
    });
    return response.data.content as FetchedColumn[];
  } catch (error: any) {
    Notification.error({
      title: 'Load columns for tasks table error',
      description: String(error),
    });
    return [];
  }
}

export async function fetchTasks({ params, filtersParams }: IOnFetchArguments) {
  const { sorting, skip, page, ...payload } = params;
  try {
    const result = await corpActionsInstanceApi.get({
      endPoint: `/task-journal`,
      params: {
        ...getPayloadWithFormattedDates({ ...payload, ...filtersParams }),
        ...getSortingParams(sorting),
        offset: page,
      },
    });
    return {
      tasks: result.data.content as ITask[],
      pagination: result.data.pagination,
    };
  } catch (error: any) {
    Notification.error({
      title: 'Get tasks error',
      description: error.message,
    });
  }
  return {
    tasks: [],
    pagination: { total: 0 },
  };
}
