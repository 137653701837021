import { Notification } from 'react-ui-kit-exante';

import { corpActionsInstanceApi } from '../../api';

import { FetchSettingsPayload, SetSettingsPayload } from './types';

export async function setSettings(payload: SetSettingsPayload) {
  const { name, section, options } = payload;
  try {
    const result = await corpActionsInstanceApi.post({
      endPoint: `/settings`,
      data: {
        name,
        section,
        options,
      },
    });
    return result.data;
  } catch (error) {
    Notification.error({
      title: 'Set settings error',
      description: String(error),
    });
  }
  return null;
}

export async function removeSettings(
  payload: SetSettingsPayload,
  onReload: VoidFunction,
) {
  const { name, section, options } = payload;
  try {
    await corpActionsInstanceApi.delete({
      endPoint: `/settings`,
      data: {
        name,
        section,
        options,
      },
    });
    Notification.success({
      title: 'Setting was deleted',
    });
    onReload();
  } catch (error) {
    Notification.error({
      title: 'Delete settings error',
      description: JSON.stringify(error),
    });
  }
  return null;
}

export async function fetchSettings({ name, section }: FetchSettingsPayload) {
  try {
    const result = await corpActionsInstanceApi.get({
      endPoint: `/settings?name=${name}&section=${section}`,
    });
    return result.data;
  } catch (error) {
    Notification.error({
      title: 'Set settings error',
      description: String(error),
    });
  }
  return null;
}

export async function fetchSettingsGroups() {
  try {
    const result = await corpActionsInstanceApi.get({
      endPoint: `/settings/sections`,
    });
    return result.data;
  } catch (error) {
    Notification.error({
      title: 'Set settings error',
      description: String(error),
    });
  }
  return null;
}
