import { initApp } from './initApp';

window.CORPORATE_ACTIONS_UI = {
  ...window.CORPORATE_ACTIONS_UI,
  render: (node: Element) => initApp(node),
};

// is rendered not inside run-ui
if (!window.runUIhistoryContainer) {
  initApp();
}
