import { FilterValue } from 'react-ui-kit-exante';

import { FetchedColumn } from '../services/types';
import { transformVariantsToSelectOptions } from '../shared/helpers';

interface Params {
  fetchedColumns: FetchedColumn[];
  excludeColumns?: string[];
  onFilter?(col: string, value: FilterValue): void;
  onRemove?(col: string): void;
}
export const getTableColumns = ({
  fetchedColumns,
  excludeColumns,
  onFilter,
  onRemove,
}: Params) => {
  const filteredColumns = excludeColumns?.length
    ? fetchedColumns.filter((item) => !excludeColumns.includes(item.name))
    : fetchedColumns;
  return (
    filteredColumns?.map((item) => ({
      Header: item.value,
      accessor: item.name,
      minWidth: item.name.length * 12,
      type: item.type,
      disableFilters: !item.filter,
      disableSortBy: !item.sort,
      filterOptions: transformVariantsToSelectOptions(item.options ?? []),
      onFilter,
      onRemove,
    })) ?? []
  );
};
