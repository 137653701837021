import { Checkbox } from 'react-ui-kit-exante';

export function getGeneralCheckbox<T>(
  items: T[],
  selectedTransactionsIds: (number | string)[],
  onCheckAllTransactionOnPage: () => void,
  onUnCheckAllTransactionOnPage: () => void,
  idExtractor: (value: T) => number | string,
) {
  const countOfSelectedTransactionsOnPage = items.reduce((acc, item) => {
    const foundElement = selectedTransactionsIds.find(
      (id) => idExtractor(item) === id,
    );
    return foundElement ? acc + 1 : acc;
  }, 0);
  const checked = items.length === countOfSelectedTransactionsOnPage;
  const handleClick = !checked
    ? onCheckAllTransactionOnPage
    : onUnCheckAllTransactionOnPage;
  return items.length ? (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className="mt-3" onClick={handleClick}>
      <Checkbox checked={checked} />
    </div>
  ) : null;
}

export function getCellCheckbox<T>(
  id: number,
  items: T[],
  selectedItemsIds: (number | string)[],
  onCheckItem: (item: T) => void,
  idExtractor: (value: T) => number | string,
) {
  const checked = !!selectedItemsIds.find((item) => item === id);
  const foundItem = items.find((item) => idExtractor(item) === id);
  if (!foundItem) {
    return null;
  }
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div onClick={() => onCheckItem(foundItem)}>
      <Checkbox checked={checked} />
    </div>
  );
}
