import { FC } from 'react';
import { ISelectOption, styled } from 'react-ui-kit-exante';

const StyledDiv = styled('div')(() => ({
  padding: '20px',
  paddingLeft: '0',
  borderRight: `1px solid #ebebeb`,
  width: '250px',
}));

const StyledUl = styled('ul')(() => ({
  listStyle: 'none',
  padding: '0',
  margin: '0',
  overflow: 'hidden',
}));

const StyledLi = styled('li')(() => ({
  marginTop: '8px',
}));

const StyledButton = styled('button')<{
  active?: boolean;
  onClick: VoidFunction;
}>(({ theme, active }) => {
  return {
    fontSize: '100%',
    backgroundColor: 'initial',
    color: theme?.color.typo.action,
    border: 'none',
    borderLeft: active ? `2px solid ${theme?.color.typo.action}` : '',
    cursor: 'pointer',
    paddingLeft: '22px',
  };
});

interface IProps {
  options: ISelectOption[];
  onSelect: (value: string | number) => void;
  selectedValue?: string;
}

export const SectionSelect: FC<IProps> = ({
  options,
  onSelect,
  selectedValue,
}) => {
  return (
    <StyledDiv>
      <StyledUl>
        {options.map((item) => (
          <StyledLi key={item.value}>
            <StyledButton
              onClick={() => onSelect(item.value)}
              active={item.value === selectedValue}
            >
              {item?.label as string}
            </StyledButton>
          </StyledLi>
        ))}
      </StyledUl>
    </StyledDiv>
  );
};
