import { getPayloadWithFormattedDates } from 'utils';

import { corpActionsInstanceApi } from '../../api';
import { getErrorMessage } from '../../api/helpers';

import { TFormValues } from './types';

export async function runScript(
  scriptName: string,
  params: TFormValues,
  isDry?: boolean,
) {
  try {
    const endPoint = `/${scriptName}`;
    const formattedParams = getPayloadWithFormattedDates(params);
    const result = isDry
      ? await corpActionsInstanceApi.get({
          endPoint,
          params: formattedParams,
        })
      : await corpActionsInstanceApi.post({
          endPoint,
          data: formattedParams,
        });
    return result.data;
  } catch (err: any) {
    return getErrorMessage(err);
  }
}

export async function fetchScripts() {
  try {
    const result = await corpActionsInstanceApi.get({
      endPoint: `/run`,
    });
    return result.data;
  } catch (err: any) {
    return getErrorMessage(err);
  }
}

export async function executeEvents(ids: number[], isReRun = false) {
  return corpActionsInstanceApi.post({
    endPoint: `/run${isReRun ? '?force=true' : ''}`,
    data: ids.map((id) => ({ id })),
  });
}

export async function rollbackEvents(ids: number[]) {
  return corpActionsInstanceApi.post({
    endPoint: '/rollback',
    data: ids.map((id) => ({ id })),
  });
}
