import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  IOnFetchArguments,
  ISortBy,
  useTableData,
  Table,
  Modal,
} from 'react-ui-kit-exante';

import { defaultOrdering, defaultPageSize, pageSizes } from 'consts';
import { fetchTaskFields, fetchTasks, ITask } from 'services/CorporateActions';
import { FetchedColumn } from 'services/types';
import { calculateCountOfPages } from 'utils';

import { ModalParams } from './types';
import { getColumns } from './utils';

const tableId = 'tasks_table';
const Tasks: FC = () => {
  const [modalParams, setModalParams] = useState({
    title: '',
    description: '',
    isShow: false,
  });
  const [taskColumns, setTaskColumns] = useState<FetchedColumn[]>([]);
  const fetchColumns = async () => {
    const taskFields = await fetchTaskFields();
    setTaskColumns(taskFields);
  };

  useEffect(() => {
    fetchColumns();
  }, []);

  const handleChangeModalParams = useCallback(
    (params: ModalParams) => {
      setModalParams(params);
    },
    [setModalParams],
  );
  const handleClose = useCallback(() => {
    setModalParams({ ...modalParams, isShow: false });
  }, [modalParams, setModalParams]);

  const getTasks = useCallback(
    (props: IOnFetchArguments) => fetchTasks(props),
    [],
  );

  const prepareFiltersForParams = useCallback(
    (params: Record<string, unknown>): Record<string, unknown> =>
      Object.keys(params).reduce(
        (acc, param) =>
          getColumns({
            onChangeModalParams: handleChangeModalParams,
            columnsList: [],
          })
            .map((column) => column.accessor)
            .includes(param as keyof ITask)
            ? { ...acc, [param]: params[param] }
            : acc,
        {},
      ),
    [],
  );

  const tableData = useMemo(
    () => ({
      tableId,
      data: { onFetch: getTasks },
      filters: {
        prepareFiltersForParams,
      },
      saveViewParamsAfterLeave: true,
      pagination: {
        getDefaultPagination: () => ({ limit: defaultPageSize, skip: 0 }),
      },
    }),
    [getTasks],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading: isLoadingCpBo,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    setSorting,
  } = useTableData<{
    tasks: ITask[];
    pagination: { total: number };
  }>(tableData);

  const pageCount = useMemo(
    () => calculateCountOfPages(data?.pagination?.total || 0, limit),
    [limit, data],
  );

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
    }),
    [filters, resetFilters],
  );

  const handleSorting = useCallback(
    (sortingArray: ISortBy[]) => {
      setSorting(sortingArray);
    },
    [setSorting],
  );
  const columns = useMemo(
    () =>
      getColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        onChangeModalParams: handleChangeModalParams,
        columnsList: taskColumns,
      }),
    [setFilter, removeFilter, handleChangeModalParams, taskColumns],
  );

  return (
    <div>
      <Table
        title="Tasks"
        tableId={tableId}
        showTableInfo
        isFlexLayout
        showScrollbar
        isPinnedHeader
        manualSortBy
        hasFilters
        hasPagination
        saveViewParamsAfterLeave
        pageSizes={pageSizes}
        displayedColumnKeys={columns.map((i) => i.accessor as string)}
        saveColumnOrder
        data={data?.tasks ?? []}
        columns={columns}
        isLoading={isLoadingCpBo}
        filteringProps={filteringProps}
        onSort={handleSorting}
        defaultSortBy={defaultOrdering}
        serverPaginationProps={{
          pageIndex: page,
          pageCount,
          pageSize: limit,
          total: data?.pagination.total || 0,
          setPage,
          setPageSize: setLimit,
        }}
      />
      <Modal
        title={modalParams.title}
        onClose={handleClose}
        isOpened={modalParams.isShow}
      >
        <div className="d-flex justify-content-center w-100">
          <pre>{modalParams.description}</pre>
        </div>
      </Modal>
    </div>
  );
};

export default Tasks;
