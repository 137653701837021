interface IErrorFallbackProps {
  error: Error;
}

export const ErrorFallback = ({ error }: IErrorFallbackProps) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <p>Reload page please</p>
    </div>
  );
};
