import { Notification } from 'react-ui-kit-exante';

import { corpActionsInstanceApi } from '../../api';
import { getErrorMessage } from '../../api/helpers';

export async function getCorporateActionDetails(id: number) {
  try {
    const resp = await corpActionsInstanceApi.get({
      endPoint: `/extend?id=${id}`,
    });
    return resp.data;
  } catch (error) {
    Notification.error({
      title: 'Get corporate action details error',
      description: getErrorMessage(error),
    });
  }
  return null;
}
